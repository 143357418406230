import { ARCanvas, ARMarker } from "@artcom/react-three-arjs";
import React, { Suspense, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Html, Splat, useProgress } from "@react-three/drei";
const Loader = () => {
  const { progress } = useProgress();
  return <Html>{progress} % loaded</Html>;
};
const splatUrls = [
  "https://d2mjt9y3unfs9x.cloudfront.net/hekisui.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/shimpaku_ishitsuki.splat",
];
const RandomSplat = () => {
  const [splatUrl, setSplatUrl] = useState("");
  useEffect(() => {
    const randomUrl = splatUrls[Math.floor(Math.random() * splatUrls.length)];
    setSplatUrl(randomUrl);
  }, []);
  return splatUrl ? <Splat src={splatUrl} /> : null;
};
const ARApp = () => (
  <ARCanvas
    gl={{
      antialias: true,
      powerPreference: "default",
    }}
    onCameraStreamReady={() => console.log("Camera stream ready")}
    onCameraStreamError={() => console.error("Camera stream error")}
    onCreated={({ gl }) => {
      gl.setSize(window.innerWidth, window.innerHeight);
    }}
    patternRatio={0.9}
  >
    <ARMarker
      params={{ smooth: true }}
      type={"pattern"}
      patternUrl={"data/gray_090.patt"}
      onMarkerFound={() => {
        console.log("Marker Found");
      }}
    >
      <Suspense fallback={<Loader />}>
        <RandomSplat />
      </Suspense>
    </ARMarker>
  </ARCanvas>
);
createRoot(document.getElementById("root")).render(<ARApp />);
